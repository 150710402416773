<template>
  <section class="payment">
    <div class="container">
      <img
        src="https://track.resume-perfect.com/adclick.php"
        width="1"
        height="1"
        border="0"
      />
      <h1 class="payment__title">
        Thank You for Subscribing to Resume-Perfect.com
      </h1>

      <div class="row justify-content-center ty-content-center">
        <form
          ref="authForm"
          class="login-form"
          @submit.prevent="redirectDashboard"
        >
          <div class="col-12 col-lg-auto">
            <div class="payment__text">
              You have paid $<span v-html="AvailedPrice"></span>. Your credit
              card will show a payment from Resume-Perfect.com.
            </div>
            <br />
            <AppButton
              title="Continue to Dashboard"
              class="form-btn"
              className="primary"
              type="submit"
              :pending="pending"
            />
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { ROUT_PATH, USER_ACCOUNT_NAMES } from "@/helpers/rout-constants";
import CompletedTemplate from "@/helpers/completed-template-hash";
import AppToast from "@/helpers/toast-methods";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";

export default {
  name: "PaymentPage",
  mixins: [mixpanelMixin],
  data() {
    return {
      ROUT_PATH,
      activePlanId: null,
      activePlan: null,
      TrialDays: null,
      Price: null,
      InitialPrice: null,
      Plan: null,
      AvailedPrice: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      expiresDate: "user/expiresDate",
      subscriptionPlans: "subscribe/getSubscriptions",
    }),
  },

  async created() {
    await this.$store.dispatch("loader/pending");
    await this.getUserData();

    this.mixpanelTrack("V2-THANKYOUPAGE");

    const plans = await this.$store.dispatch(
      "subscribe/getAllSubscriptionPlans"
    );

    this.activePlanId = this.user.payment_subscription[0].identity;
    this.activePlan = plans.find(
      (price) => price.identity === this.activePlanId
    );

    let config = JSON.parse(this.activePlan.config);
    this.TrialDays = config[0].trial_days;
    this.Plan = config[0].plan;

    if (config[0].price > 0) {
      this.Price = (config[0].price / 100).toFixed(2);
    }
    if (config[0].initial_price > 0) {
      this.InitialPrice = (config[0].initial_price / 100).toFixed(2);
    }

    this.AvailedPrice = this.Price;
    if (this.InitialPrice > 0) {
      this.AvailedPrice = this.InitialPrice;
    }

    await this.$store.dispatch("loader/done");
  },

  methods: {
    async getUserData() {
      try {
        await this.$store.dispatch("user/getUser");
      } catch (e) {
        console.error(e);
      }
    },
    async redirectDashboard() {
      if (CompletedTemplate.get()) {
        await this.$store.dispatch("loader/done");

        const name =
          CompletedTemplate.get().type === "resume"
            ? USER_ACCOUNT_NAMES.USER_RESUME_DETAILS
            : USER_ACCOUNT_NAMES.USER_LETTER_DETAILS;

        await this.$router.push({
          name,
          params: { id: CompletedTemplate.get().hash },
          query: { uncompletedTemplate: CompletedTemplate.get().hash },
        });

        return;
      } else {
        await this.$router.push({
          name: USER_ACCOUNT_NAMES.USER_DASHBOARD,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ty-content-center {
  text-align: center;
}

.payment {
  padding: 80px 0 370px;

  @media (max-width: map_get($grid-breakpoints, lg)) {
    padding: 32px 0 67px;
  }

  &__title {
    margin-bottom: 24px;
    text-align: center;

    @media (max-width: map_get($grid-breakpoints, md)) {
      text-align: left;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 140%;
    color: $light-gray;
    margin-top: 50px;
    margin-bottom: 80px;

    @media (min-width: map_get($grid-breakpoints, lg)) {
      max-width: 518px;
    }
  }

  &__submit {
    min-width: 50%;
  }
}
</style>
